import { render, staticRenderFns } from "./flv.vue?vue&type=template&id=37bee091&scoped=true&"
import script from "./flv.vue?vue&type=script&lang=js&"
export * from "./flv.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37bee091",
  null
  
)

export default component.exports